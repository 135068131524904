import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import PMPortrait from '../../content/images/pm-abiy-2.jpg'
import Poster1Img from '../../content/images/poster-1.jpg'
import Poster2Img from '../../content/images/poster-2.jpg'

class WhatIsMedemer extends React.Component {
  renderVideo = videoId => {
    return (
      <>
        <iframe
          width="100%"
          height={357}
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&showinfo=0&controls=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </>
    )
  }

  render() {
    const { location, data } = this.props
    const profile = get(data, 'profile.childImageSharp.fixed')
    const work1 = get(data, 'work1.childImageSharp.sizes')
    const work2 = get(data, 'work2.childImageSharp.sizes')
    const back1 = get(data, 'back1.childImageSharp.sizes')
    const back2 = get(data, 'back2.childImageSharp.sizes')

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="What is Medemer?" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">What is Medemer?</h1>
              <p className="lead text-muted">
                Medemer <dfn title="HyperText Markup Language">(verb)</dfn> -
                Addition: the action or process of adding something to something
                else.
              </p>
            </div>
          </section>

          <div className="container marketing">
            <div className="row featurette">
              <div className="col-md-7 order-md-1">
                <p>
                  The Prime Minister’s office of Dr. Abiy Ahmed is launching
                  Medemer, a public service announcement campaign to further
                  build a dialogue on the philosophy surrounding the ideas
                  embedded in Medemer.
                </p>
                <p>
                  The key messages are available in the first publication book
                  “Medemer” available online for order. To further support the
                  campaign, the Prime Minister’s office is releasing
                  communication apparatus to expand the dialogue and build on
                  the concept of “Medemer”.
                </p>
                <p>
                  Following the launch, the Prime Minister’s office will have an
                  open dialogue in various forums about the practical
                  implementation of the four main pillars embodied in Medemer:
                </p>
                <p>
                  <ol>
                    <li>Building a vibrant democracy</li>
                    <li>Economic vitality</li>
                    <li>Encouraging public-private partnerships (PPPs)</li>
                    <li>Regional integration and openness to the world</li>
                  </ol>
                </p>

                <p>
                  {this.renderVideo('5gdjjMFG4D4')}
                  አዲስ ወግ አንድ ጉዳይ፣ ውይይት በመደመር ላይ፡፡ ክፍል አንድ
                </p>
                <hr height="1" />
                <p>
                  {this.renderVideo('B84Mk_EuVdA')}
                  አዲስ ወግ አንድ ጉዳይ፣ ውይይት በመደመር ላይ፡፡ ክፍል ሁለት
                </p>
                <hr height="1" />
                <p>
                  {this.renderVideo('1HXjQ7-etis')}
                  PMO ETH briefing to members of the diplomatic community &
                  international organizations on Medemer
                </p>
                <hr height="1" />
                <p>
                  {this.renderVideo('sQxyX8dx5B4')}
                  መደመር መፅሀፍ የኢትዮጵያ ኢኮኖሚ ያጋጠሙትን ችግሮች ለመፍታት የተቋማት መተባበር ቁልፍ ጉዳይ
                  መሆኑን ይገልፃል{' '}
                </p>
                <hr height="1" />
                <p>
                  {this.renderVideo('r3DDX-kMlJI')}
                  በመደመር መሰረታዊ እሳቤዎችና ፖለቲካዊ ርዕዮት ላይ ያተኮረ ውይይት
                </p>

                {/*
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h2>Building a vibrant democracy</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <p>
                          Building a democratic and pluralist democracy requires
                          quick and decisive actions to enhance the citizens’
                          trust in the government.
                        </p>
                        <p>
                          While the Ethiopian economy registered rapid growth
                          averaging 10% per annum for the past decade, the
                          society has stagnated in a period of authoritarianism.
                          Political freedom and democracy were seriously
                          undermined by the government’s repressive methods.
                        </p>
                        <p>
                          Therefore, the new prime minister has pledged to
                          review restrictive laws of the past, including the
                          antiterrorism law, often used to silence opposing
                          views. In addition, over 13,000 prisoners were freed
                          as part of an amnesty campaign and the parliament
                          legalized two opposition groups with secessionist
                          aspirations, the Oromo Liberation Front (OLF) and the
                          exiled movement ‘Ginbot 7’. A revised civil society
                          law should also be introduced soon. The president of
                          the Somali regional state, Abdi Mohamoud Omar, known
                          as Abdi Illey, was also ousted from his position,
                          principally due to his role as commander of the
                          repressive Liyu Police, accused of serious human
                          rights’ violations.
                        </p>
                        <p>
                          On gender parity, the country has also made the
                          headlines. Half of Abiy’s new cabinet is made up of
                          women, the parliament appointed Sahle-Wok Zewde, a
                          former diplomat as the first female president of the
                          country and women are also heading the Supreme Court
                          (Meaza Ashenafi) and the electoral commission
                          (Birtukan Mideksa).
                        </p>
                        <div>
                          <br />
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      <h2>Economic vitality</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <p>
                          Ethiopia is a low-income country with very low
                          adaptive capacity. The main sector is agriculture (40%
                          of GDP and 70% of employment), which means that much
                          of its economy depends on its climate. Considering
                          this vulnerability, the government is now implementing
                          its second Growth and Transformation Plan (GTPII)
                          aiming at boosting the manufacturing sector and
                          building the adequate structures for a green
                          climate-resilient Middle- Income Country by 2025. The
                          first five-year plan (GTP I- 2010-2015), and its
                          successor (GTP II- 2015-2020) were designed to shift
                          the country from an agricultural to a
                          manufacturing-based economy by relying on the massive
                          development of huge infrastructure projects, including
                          the expansion of transport links and hydroelectric
                          power. Nonetheless, it seems that the previously
                          established development model has reached its limits
                          and that the new government had to rapidly think of a
                          new strategy to save the economy.
                        </p>
                        <p>
                          As the second demographic power in Africa, the
                          government needs to ensure citizens have the adequate
                          environment to fulfil their potential by improving the
                          education system and investing in the aspiration of
                          the youth. Ethiopia cannot reach the center of
                          international market without the involvement of its
                          women and youth. Therefore, creating a dynamic economy
                          requires:
                        </p>

                        <ul>
                          <li>
                            Facilitating access to finance for small and medium
                            size enterprises;
                          </li>

                          <li>
                            Easing regulations to start business and improving
                            the policy environment (institutional reforms,
                            commercial and investment codes, and other business
                            regulations);
                          </li>

                          <li>
                            Making the private sector an integral part of the
                            economy and opening it to international business
                            (sale of minority shares of state-owned enterprises
                            and reallocation of public expenditure);
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      <h2>Encouraging Public-Private Partnerships (PPPs)</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <p>
                          It is also important for the future of the continent
                          to enable countries to grow their economies beyond
                          primary commodities and develop other sector that can
                          help in accelerating their socioeconomic development.
                          In this sense, Abiy’s new administration encourages
                          enhanced regional integration.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      <h2>Regional integration and openness to the world</h2>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <p>
                          Interdependency reduces the likelihood of conflicts
                          between neighbors. Dr. Ahmed uses the following phrase
                          to illustrate his thought: “if you want to go fast go
                          alone, but if you want to go far go together.” It does
                          indeed reflect in Ethiopia’s multilateral approach,
                          notably in joining the Continental Free Trade
                          Agreement (CFTA) and through its active engagement in
                          various regional and continental institutions, whether
                          the Intergovernmental Authority on Development
                          (IGAD)4, the Common Market for Easter and Southern
                          Africa (COMESA)5, or in the African Union (AU). A
                          continent at peace will also create a less favorable
                          environment for criminal activities that threaten the
                          security of states and their citizens. The country’s
                          surprising rapprochement with its long-dated nemesis,
                          Eritrea, opened the opportunity for the creation of a
                          peace belt in the region, which ideally, could run
                          west to east and secure a cooperation road that
                          crosses the continent.
                        </p>
                        <p>
                          Besides, Ethiopia and its neighbors can optimize their
                          chances of openness to the world by finding ways of
                          creating synergies and saving resources. Dr. Ahmed
                          goes on, advocating for a single diplomatic post that
                          could represent all IGAD countries, which could
                          tremendously save resources and allow for a better
                          regional coordination on development matters.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
               */}
              </div>
              <div className="col-md-5 order-md-2">
                {/* <img src={Poster1Img} width="100%" className="img-responsive" />
                <br />
                <img src={Poster2Img} width="100%" className="img-responsive" /> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WhatIsMedemer

export const query = graphql`
  query WhatIsMedemerPageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    work1: file(name: { eq: "work1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work2: file(name: { eq: "work2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    work3: file(name: { eq: "work3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
